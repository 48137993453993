/*
 * Cribbed, in part, from the following excellent pieces of code:
 *
 * - https://github.com/jquery/jquery/blob/master/src/core/ready.js
 * - https://github.com/ded/domready/blob/master/ready.js
 *
 */
export function domReady(func) {
	if (document.readyState === 'loading') {
		document.addEventListener('DOMContentLoaded', func)
	} else {
		window.setTimeout(func, 0)
	}
}

/*
 * Query selectors with optional context.
 */
export function querySelector(query, context = document) {
	return context.querySelector(query)
}
export { querySelector as qs }

export function querySelectorAll(query, context = document) {
	return Array.from(context.querySelectorAll(query))
}
export { querySelectorAll as qsa }

/*
 * Attribute getting, setting, and removing.
 */
export function getAttribute(element, attribute) {
	return element.getAttribute(attribute)
}

export function setAttribute(element, attribute, value) {
	return element.setAttribute(attribute, value)
}

export function removeAttribute(element, attribute) {
	return element.removeAttribute(attribute)
}

export function hasAttribute(element, attribute) {
	return element.hasAttribute(attribute)
}

export function toggleAttribute(element, attribute, valueOrCondition, condition) {
	let cond = condition
	let val = valueOrCondition

	/*
	 * If the condition is missing then the valueOrCondition variable is the
	 * condition and we should use an empty string as the value. This assumes that
	 * we're working with a boolean attribute like "hidden".
	 */
	if (condition === undefined || condition === null) {
		cond = valueOrCondition
		val = ''
	}

	if (cond) {
		return setAttribute(element, attribute, val)
	} else {
		return removeAttribute(element, attribute)
	}
}

/*
 * Because I forgot to call `.content` once and it broke MoEngage.
 */
export function getMetaContent(name) {
	const element = querySelector(`meta[name="${ name }"]`)

	if (element) {
		return getAttribute(element, 'content')
	}
}
export { getMetaContent as meta }

/*
 * Unwrapping an element means shifting its content to it's parent element.
 */
export function unwrap(element) {
	const parentNode = element.parentNode

	while(element.firstChild) {
		parentNode.insertBefore(element.firstChild, element)
	}

	parentNode.removeChild(element)
}

/*
 * Scroll an element into view.
 */
export function scrollIntoView(element) {
	element.scrollIntoView(true)
}
